<template>
  <div>
    <!--begin::Members-->
    <div class="card card-custom gutter-b">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Member List</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
          <!-- v-if="user_permissions.includes('chapter_create_subaccounts')" -->
            <div class="my-2">
              <v-row justify="center">
                <a
                  href="#"
                  @click="redirectToCreateMember()"
                  class="btn btn-primary font-weight-bolder mr-5"
                >
                  <span class="svg-icon svg-icon-md">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,
                            10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  Invite Member
                </a>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_member_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-card-title class="pt-0 pr-0">
                <v-spacer></v-spacer>
                <div class="col-md-4 my-2 my-md-0 pr-0">
                  <div class="input-icon">
                    <v-text-field
                      v-model="search"
                      single-line
                      placeholder="Search"
                      class="form-control"
                      hide-details
                    ></v-text-field>
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </v-card-title>
              <v-data-table :headers="headers_chapter" :items="member_list">
                <template v-slot:item="row">
                  <tr>
                    <td>
                      {{ row.item.first_name }} &nbsp; {{ row.item.last_name }}
                    </td>
                    <td>{{ row.item.email }}</td>
                    <td>{{ row.item.member_role ? row.item.member_role.name : "Member" }}</td>
                    <td>
                      {{ row.item.status == 0 ? "InActive" : "Active" }}
                    </td>
                    <td>
                      <v-tooltip top>
                      
                        <template v-slot:activator="{ on }">
                          <v-btn
                          v-if="user_permissions.includes('chapter_create_subaccounts') || is_company_admin == 1"
                            class="mx-1"
                            fab
                            small
                            v-on="on"
                            @click="memberEdit(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Member</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                          v-if="user_permissions.includes('chapter_create_subaccounts')  || is_company_admin == 1"
                            class="mx-1"
                            fab
                            small
                            v-on="on"
                            @click="memberDelete(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Member</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-1"
                            fab
                            small
                            v-on="on"
                            @click="memberManage(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-account-search</v-icon>
                          </v-btn>
                        </template>
                        <span>Manage Member</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Members-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import EventBus from "@/core/services/store/event-bus";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import { GET_USER } from "@/core/services/store/user.module";
import { DELETE_MEMBER } from "@/core/services/store/member.module";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "membersList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      member_list: [],
      user_permissions: [],
      search: "",
      dialog: false,
      member_file: {},
      disable_import: false,
      fileRules: [
        value =>
          !value ||
          value.size < 5000000 ||
          "File size should be less than 5 MB!"
      ],
      company_type: "Small Enterprise",
      headers: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Email", value: "doc_type", width: "25%" },
        { text: "Designation", value: "role", width: "15%" },
        { text: "Action", sortable: false, value: "action", width: "20%" }
      ],
      headers_chapter: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Email", value: "doc_type", width: "25%" },
        { text: "Designation", value: "role", width: "15%" },
        { text: "Status", value: "status", width: "15%" },
        { text: "Action", sortable: false, value: "action", width: "20%" }
      ],
      is_company_admin : 2,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getCompany","getUserPermissions","getUser"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    let context = this;
    this.$store.dispatch(GET_COMPANY);
    EventBus.$on("GET_COMPANY", function() {});
    
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Members" }]);
    this.getMembersList();
    if (this.getCompany && this.getCompany.length > 0) {
      this.company_type = this.getCompany.company_type;
    } else {
      this.$store.dispatch(GET_COMPANY).then((data) => {
        this.company_type = this.getCompany.company_type;
      });
    }

    this.$store.dispatch(GET_USER);
    EventBus.$on("GET_USER", function() {});
    this.$store.dispatch(GET_USER).then((data) => {
        this.is_company_admin = this.getUser.is_company_admin;
    });
  },
  watch: {
    search: function(newVal) {
      this.getMembersList(newVal);
    },
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    },
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    },
    getUser: function(newVal) {
       this.is_company_admin = newVal.is_company_admin;
    },
  },
  methods: {
    memberManage(emp_id) {
      this.$router.push({ name: "members.manage", params: { id: emp_id } });
    },
    memberDelete(emp_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Member!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_MEMBER, emp_id).then(() => {
            this.getMembersList();
          });
        } else if (result.isDismissed) {
          Swal.fire("Member is safe.", "", "info");
        }
      });
    },
    redirectToCreateMember() {
      this.$router.push({ name: "membersCreate" });
    },
    getMembersList(search_str = "") {
      let context = this;
      let api_url = "member";
      if (search_str) {
        api_url = "member?search_str=" + search_str;
      }
      axios({
        method: "get",
        url: api_url,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.member_list = result.data.members;
        },
        function() {
          Swal.fire("Error", "Error in fetching members list.", "info");
        }
      );
    },
    memberEdit(emp_id) {
      this.$router.push({ name: "members.edit", params: { id: emp_id } });
      // this.$store.dispatch(GET_DOCUMENT_BY_ID, doc_id).then(() => {
      //   this.$router.push({ name: "documents.edit", params: { id: doc_id } });
      // });
      // Swal.fire("Upcomming feature.", "", "info");
    }
  }
};
</script>
